<template>
  <div class="gift-select mt-md-3">

    <!-- flex child 1 -->
    <div class="child-1 d-flex flex-column align-items-start">
      <form action="">
        <div class="input-wrap radio mb-1">
          <input type="radio" name="opt" id="opt-in" value="1" v-model="state.opt" @input="onInput($event)">
          <label for="opt-in">Yes, I would like to receive my gift.</label>
        </div>

        <div v-if="store.gift.optInSelected" class="info-wrap rounded bg-bonvoy-white p-3 mt-0-5 mb-2">
          <p class="fz-3xs mb-0-5">Please ensure your shipping address and contact information are correct. Updating your address here will not update the address listed on your member profile.</p>
          <div class="info-details position-relative pr-6 mb-0-5">

            <a href="#" class="edit link-2 d-flex align-items-center" data-toggle="modal" data-target="#modal-edit">
              <img :src="`${store.baseUrl}/images/icon_edit.png`" width="12" height="12" style="width: 12px; height: 12px;" alt="">
              <p class="ml-0-5">Edit</p>
            </a>

            <div class="mt-2 mb-0-5">
              <h3 class="info-heading">Shipping Information:</h3>
              <p class="name">{{ store.gift.firstName }} {{ store.gift.lastName }}</p>
              <p class="address-1">{{ store.gift.address1 }}</p>
              <p v-if="store.gift.address2" class="address-2">{{ store.gift.address2 }}</p>
              <p class="city-state-zip">{{ store.gift.city }}{{ store.stateProvDisplay }} {{ store.gift.postalCode }}</p>
              <p class="country">{{ country }}</p>
            </div>

            <div class="mt-1" v-if="(store.gift.phoneNumber) || store.gift.emailAddress">
              <h3 class="info-heading mt-2">Contact Information:</h3>
              <p class="phone" v-if="store.gift.phoneNumber">{{ store.gift.phoneNumber }}</p>
              <p class="email" v-if="store.gift.emailAddress">{{ store.gift.emailAddress }}</p>
            </div>

          </div>

          <button @click.prevent="onConsent" @keydown.enter.prevent="onConsent" class="input-wrap checkbox mt-2 mb-0">
            <input type="checkbox" id="consent" name="consent" v-model="state.consent" @input="onInput($event)" class="hide">
            <label for="consent" class="text-left">I consent to sharing my contact information for the purpose of receiving my <span class="no-wrap">complimentary gift.</span></label>
            <span class="checkmark"></span>
          </button>
        </div>

        <div v-if="store.gift.optInSelected && !store.gift.allRequiredFieldsFilled" class="errors rounded mb-3">
          <p>Required information is missing. Please use the "Edit" link above to update your information.</p>
          <i class="icon-error"></i>
        </div>

        <div v-if="state.errors" class="errors rounded mb-3">
          <ul class="list-style-none ml-0">
            <li v-for="(error, index) in state.errors" :key="index">{{ error[0] }}</li>
          </ul>
          <i class="icon-error"></i>
        </div>

        <div class="input-wrap radio">
          <input type="radio" name="opt" id="opt-out" value="0" v-model="state.opt" @input="onInput($event)">
          <label for="opt-out">No, I would like to decline my gift.</label>
        </div>

        <div class="d-flex mt-3 mb-0-5">
          <button @click.prevent="onSubmit" @keydown.enter.prevent="onSubmit" type="submit" class="btn-1" :disabled="!state.canSubmit || (store.gift.optInSelected && !store.gift.allRequiredFieldsFilled)">Submit</button>

          <div class="loading-spinner ml-2" ref="spinnerEl"></div>
        </div>

        <p v-if="isPlatinum" class="note fz-3xs mt-2"><span class="bold">Note:</span> You have through {{ dateOfGiftSuppression }}, to opt in to receive your gift. If you have additional questions, see <a :href="faqsHref" class="link-1" target="_blank">FAQs</a>.</p>
      </form>
    </div>

    <!-- flex child 2 -->
    <div class="child-2 mb-3 mb-md-0">
      <video muted loop autoplay playsinline class="rounded w-100">
        <source :src="videoSrc" type="video/mp4">
        {{ noVideoSupport }}
      </video>
    </div>

  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue';
import { useStore } from '../store';
import { q } from '../utilities';
import axios from 'axios';
import { Spinner } from 'spin.js';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

const store = useStore();

const props = defineProps({
  isPlatinum: [Boolean, Number],
  dateOfGiftSuppression: [String, Date],
  videoSrc: String,
});

const state = reactive({
  canSubmit: false,
  opt: 1,
  consent: 0,
  errors: null,
});

const faqsHref = ref(content.url.faqs);

const country = computed(() => {
  const countryCode = store.gift.countryCode;
  const countries = store.ref.countries;

  for (const key in countries) {
    if (!countries.hasOwnProperty(key)) continue;

    const obj = countries[key];
    for (const prop in obj) {
      if (!obj.hasOwnProperty(prop)) continue;

      if (obj[prop] === countryCode) {
        return obj.Label;
      }
    }
  }
});

const spinnerEl = ref(null);

const noVideoSupport = ref(content.no_video_support);

function onInput(e) {
  if (e.target.id === 'opt-out') {
    store.gift.optInSelected = false;
    state.canSubmit = true;
  } else if (e.target.id === 'opt-in') {
    store.gift.optInSelected = true;
    state.canSubmit = false;
  }
}

function onConsent() {
  const checkboxEl = q('.gift-select input[type="checkbox"]');
  checkboxEl.checked ? checkboxEl.checked = false : checkboxEl.checked = true;
  checkboxEl.checked ? state.canSubmit = true : state.canSubmit = false;
}

const spinner = new Spinner({
  lines: 10,
  color: '#000',
  animation: 'spinner-line-fade-more',
});

function showSpinner() {
  spinnerEl.value.classList.add('show');
  spinner.spin(spinnerEl.value);
}

function hideSpinner() {
  spinnerEl.value.classList.remove('show');
  spinner.stop(spinnerEl.value);
}

function onSubmit() {
  if (state.canSubmit) {
    console.log('submitted gift form');
    showSpinner();
    state.errors = null;

    const url = '/form/giftopt';
    const formData = store.gift;

    formData['opt'] = state.opt;
    formData['consent'] = state.consent;

    axios
      .post(url, formData)
      .then(function (response) {
        console.log('gift form success');
        store.gift.optInStatus = state.opt == 1 ? 'opt_in' : 'opt_out'
      })
      .catch(function (error) {
        console.log('gift form error');
        //state.errors = 'oh no';

        if (error.response) {
          switch (error.response.status) {
            // not authenticated
            case 401:
              window.location.href = appUrls.signedout;
              break;

            // member not authorized to make a selection
            case 403:
            // fall through

            // session expired or invalid csrf token
            case 419:
              window.location.reload(); //TODO can we do this more gracefully?
              break;

            // server-side validation failed
            case 422:
              // handle errors from choice service or FormRequest validation
              if (error.response.data.service_errors) {
                showError(error.response.data.service_errors.message, true);
              } else if (error.response.data.errors) {
                // let firstError = Object.keys(error.response.data.errors)[0];
                // showError(formErrors[error.response.data.errors[firstError][0]], true);
                console.log(error.response.data.errors);
                state.errors = error.response.data.errors;
              } else {
                //console.log('I dont know what to do -- redirecting to general error page.');
                window.location.href = appUrls.error;
              }
              break;

            case 500:
            // fall through
            default:
              window.location.href = appUrls.error;
          }
        } else {
          window.location.href = appUrls.error;
        }

      })
      .then(function () {
        hideSpinner();
      });
  }
}
</script>
