<template>
  <div id="regions-map">
    <nav class="d-flex justify-content-center pt-1 pt-sm-2 mb-2 mb-sm-4">
      <ul class="desktop list-style-none d-none d-lg-flex m-0">
        <li v-for="(region, index) in regions" :key="index">
          <button @click="onRegionSelect($event)" @keydown.enter="onRegionSelect($event)" :class="{ 'active': index === 0 }" :data-region="region.key">{{ region.title }}</button>
        </li>
      </ul>
      <div class="dropdown d-lg-none position-relative">
        <button class="dropdown-toggle d-flex justify-content-center w-100" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="d-flex justify-content-between align-items-center w-100">
            <span class="mr-3" id="region-nav-label">{{ filterRegion[0].title }}</span>
            <img src="/images/icon_caret-down.png" class="arrow" alt="">
          </div>
        </button>
        <ul class="dropdown-menu w-100 m-0" aria-labelledby="region-nav-label">
          <li v-for="(region, index) in regions" :key="index" class="d-flex p-0 m-0">
            <button @click="onRegionSelect($event)" @keydown.enter="onRegionSelect($event)" :class="{ 'active': index === 0 }" :data-region="region.key">{{ region.title }}</button>
          </li>
        </ul>
      </div> <!-- mobile nav dropdown -->
    </nav>
    <div class="map-rankings-wrap d-flex flex-column flex-lg-row">
      <div class="map mr-2">
        <img :src="regionImg" alt="">
      </div>
      <div class="swiper-outer-wrap">
        <swiper @slideChangeTransitionStart="onSlideChange" :modules="[Navigation, Pagination]" :navigation="swiperNavOpts" :pagination="swiperPagOpts" :loop="true">
          <swiper-slide v-for="(item, index) in rankings" :key="index">
            <h3 class="heading fz-lg mb-2 mb-lg-3" v-html="item.heading"></h3>
            <ul class="list-style-none m-0" tabindex="0">
              <li v-for="(li, index_2) in item.list" :key="index_2">
                <a v-if="selectedRanking === 'properties'" :href="li.href" class="d-flex" target="_blank">
                  <div class="map-location-icon" v-if="index_2 < 5">
                    <img :src="`/images/icon_location-${index_2 + 1}.png`" alt="">
                  </div>
                  <div class="circled-number" v-else>
                    <span>{{ index_2 + 1 }}</span>
                  </div>
                  <span class="text link-3" v-html="li.name"></span>
                </a>
                <div v-else-if="selectedRanking === 'cities'" class="d-flex">
                  <div class="map-location-icon" v-if="index_2 < 5">
                    <img :src="`/images/icon_location-${index_2 + 1}.png`" alt="">
                  </div>
                  <div class="circled-number" v-else>
                    <span>{{ index_2 + 1 }}</span>
                  </div>
                  <span class="text" v-html="li"></span>
                </div>
              </li>
            </ul>
          </swiper-slide>
          <template #container-end>
            <button class="swiper-button-prev" aria-label="Previous"></button>
            <button class="swiper-button-next" aria-label="Next"></button>
            <div class="swiper-pagination"></div>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { q } from '../utilities';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useStore } from '../store';

const store = useStore();

const levelName = store.levelName.toLowerCase();

const swiperNavOpts = {
  prevEl: '.swiper-button-prev',
  nextEl: '.swiper-button-next',
}
const swiperPagOpts = {
  el: '.swiper-pagination',
  clickable: true,
  bulletElement: 'button',
}

const regions = mapContent.regions; // From map.php, `mapContent` defined in base.blade

const selectedRegion = ref(regions[0].key);
const filterRegion = ref(regions.filter(x => x.key === selectedRegion.value));
const rankings = ref(filterRegion.value[0][`rankings_${levelName}`]);
const selectedRanking = ref(rankings.value[0].key);
const regionImg = ref(`/images/regions/${selectedRegion.value}_${selectedRanking.value}_${levelName}.jpg`);

function onRegionSelect(e) {
  q('#regions-map button.active').forEach(el => el.classList.remove('active'));
  const regionId = e.target.dataset.region;
  const activeEls = q(`#regions-map button[data-region="${regionId}"]`);
  activeEls.forEach(el => el.classList.add('active'));
  regionImg.value = `/images/regions/${regionId}_${selectedRanking.value}_${levelName}.jpg`;
  selectedRegion.value = regionId;
  filterRegion.value = regions.filter(x => x.key === selectedRegion.value);
  rankings.value = filterRegion.value[0][`rankings_${levelName}`];
}

function onSlideChange(swiper) {
  selectedRanking.value = rankings.value[swiper.realIndex].key;
  const imgPathBeginning = regionImg.value.split('_')[0];
  regionImg.value = `${imgPathBeginning}_${selectedRanking.value}_${levelName}.jpg`;
}
</script>
