import './init-vue';
import Swiper, { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

const regionSections = content.celebrate.region_sections;

for (let i = 0; i < regionSections.length; i++) {
  new Swiper(`.swiper-celebrate-${i + 1}`, {
    slidesPerView: 'auto',
    loop: true,
    loopedSlides: regionSections[i].length,
    modules: [Pagination],
    pagination: {
      el: `.swiper-pagination-celebrate-${i + 1}`,
      clickable: true,
    },
  });
}
