<template>
  <div class="container">

    <section>
      <div class="text-center">
        <div class="mxw-body">
          <h1 class="heading mb-3">Thank you! You’ve successfully submitted your responses.</h1>
        </div>
        <div class="mxw-md">
          <p>Now we invite you to return to your Lifetime Journey to view your personal <span class="no-wrap">Marriott Bonvoy</span><sup>®</sup> highlights and to continue learning about your Lifetime {{ levelNameStr }} <span class="no-wrap">Elite community.</span></p>
        </div>
        <a :href="store.celebrateLifetimeUrl" class="btn-1 mt-3 mb-5" target="_blank">View My Journey</a>
      </div>
    </section>

    <div class="container"><hr class="mb-0"></div>

    <section class="mt-5 mb-0-5">
      <div class="mxw-body">

        <div class="text-center">
          <p class="subheading fz-md" style="max-width: 625px; margin: 0 auto;">Be among the first to experience these new hotels and new possibilities as you try out your luggage <span class="no-wrap">tag set.</span></p>
        </div>

        <div v-for="(item, index) in regionSections" :key="index" class="region-sections mt-3">
          <h2 class="heading text-center mb-3" v-html="item.heading"></h2>
          <div class="flex-grid d-none d-sm-flex">
            <CelebrateModules :data="item.modules" />
          </div>
          <div :class="`swiper swiper-celebrate swiper-celebrate-${index + 1} d-sm-none`">
              <div :class="`swiper-pagination swiper-pagination-celebrate-${index + 1}`"></div>
              <div class="swiper-wrapper">
                <CelebrateModules :data="item.modules" />
              </div>
          </div>
        </div>

        <div class="text-center">
          <a href="https://www.marriott.com/default.mi" class="btn-1 mt-3 mt-md-5" target="_blank">Explore Now</a>
        </div>

      </div>
    </section>

  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStoreCelebrate } from '../store';
import CelebrateModules from './CelebrateModules';

const store = useStoreCelebrate();

const regionSections = ref(content.celebrate.region_sections);
const levelNameStr = ref(content[levelName]);

console.log(store.celebrateLifetimeUrl);
</script>
