import { defineStore } from 'pinia';
import { formatPhoneNumber } from './utilities';

export const useStore = defineStore('main', {
  state: () => {
    return {
      baseUrl,
      levelName,
      videoShareUrl: videoShareUrl,
      gift: {
        optInStatus: giftStatus,
        optInSelected: true,
        allRequiredFieldsFilled: null,
        firstName: giftChoice.FirstName,
        lastName: giftChoice.LastName,
        address1: giftChoice.Address1,
        address2: giftChoice.Address2,
        city: giftChoice.City,
        stateProv: giftChoice.StateProv,
        stateProvText: null,
        postalCode: giftChoice.PostalCode,
        countryCode: giftChoice.CountryCode,
        phoneCountryCode: giftChoice.PhoneCountryCode,
        phoneNumber: formatPhoneNumber(giftChoice.PhoneNumber),
        emailAddress: giftChoice.EmailAddress,
      },
      ref: {
        countries: countries,
        states: states,
        countryPhones: countryPhones,
      },
    };
  },
  getters: {
    stateProvDisplay: (state) => {
      if (state.gift.stateProvText) {
        return ', ' + state.gift.stateProvText;
      } else if (state.gift.stateProv) {
        return ', ' + state.gift.stateProv;
      } else {
        return '';
      }
    }
  },
});

export const useStoreCelebrate = defineStore('celebrate', {
  state: () => {
    return {
      surveyComplete: surveyComplete,
      formError: false,
      celebrateLifetimeUrl,
    }
  },
});
