<template>
  <template v-if="store.gift.optInStatus === 'not_set'">
    <SectionLeadIn
      heading-caps="Complimentary Gift"
      heading="A Gift of Gratitude"
      :max-width="700"
    >
      <template #subheading>We’d like to send you a complimentary luggage tag set to highlight your status and show that you’re part of the Lifetime {{ store.levelName }} Elite community.</template>
    </SectionLeadIn>
    <GiftSelect :is-platinum="isPlatinum" :date-of-gift-suppression="dateOfGiftSuppression" :video-src="videoSrc" />
  </template>
  <template v-else>
    <GiftConfirm />
  </template>
</template>

<script setup>
import { useStore } from '../store';
import SectionLeadIn from '../components/SectionLeadIn';
import GiftSelect from './GiftSelect';
import GiftConfirm from './GiftConfirm';

const store = useStore();
const props = defineProps({
  isPlatinum: [Boolean, Number],
  dateOfGiftSuppression: [String, Date],
  videoSrc: String,
});
</script>
