<template>
  <section class="hero"></section>
  <CelebrateThanks v-if="store.surveyComplete" />
  <CelebrateSurvey v-else />
</template>

<script setup>
import { useStoreCelebrate } from '../store';
import CelebrateSurvey from './CelebrateSurvey';
import CelebrateThanks from './CelebrateThanks';

const store = useStoreCelebrate();
</script>
