import { q } from './utilities';

// -------------
// Init jquery, popper.js, & Bootstrap
try {
  window.Popper = require('popper.js').default;
  window.$ = window.jQuery = require('jquery');
  require('bootstrap');
} catch (e) { console.log(e); }

Popper.Defaults.modifiers.computeStyle.enabled = false;

// -------------
// Include JS based on page
if (q('#celebrate-lifetime-page')) {
  require('./celebrate-lifetime');
}

if (q('#celebrate-page')) {
  require('./celebrate');
}
