<template>
  <ModalBase id="modal-boutiques" class="fz-xs">

    <h2 class="heading-caps mb-3">Instructions</h2>
    <h3 class="subheading mb-2">40% Boutiques Lifetime Recognition Discount</h3>

    <div class="intro-wrap mb-4">
      <p>To redeem the 40% discount promotional code from any of the Marriott retail brands listed below, call one of the numbers below, based on your country of residence. Have your promotional code and your <span class="no-wrap">Marriott Bonvoy</span> membership number ready.</p>
      <p>In the United States, call <a href="tel:18003110708" class="link-1"><span class="no-wrap">+1 800 311 0708</span></a> Monday through Friday between <span class="no-wrap">9 a.m.</span> and <span class="no-wrap">10 p.m. ET</span> and Saturday and Sunday between <span class="no-wrap">9 a.m.</span> and <span class="no-wrap">4 p.m. ET.</span></p>
      <p>In Europe and the Middle East, call <a href="tel:33141515153" class="link-1"><span class="no-wrap">33 1 41 51 51 53</span></a> Monday through Friday between <span class="no-wrap">9 a.m.</span> and <span class="no-wrap">6 p.m. CET.</span></p>
      <p>In Asia Pacific, call <a href="tel:862166608330" class="link-1"><span class="no-wrap">86 21 6660 8330</span></a> Monday through Friday between <span class="no-wrap">9 a.m.</span> and <span class="no-wrap">6 p.m. CST.</span></p>
    </div>

    <h2 class="heading-caps mb-2 pt-1">Terms and Conditions</h2>

    <ul class="roman ml-2">
      <li>Upon the member’s selection of a 40% discount of a room or home diffuser set from a Marriott retail brand, the member will receive a one-time use promotional code that can be redeemed for 40% off the full retail value of one of these room or home diffuser sets:
        <ul class="lower-alpha mb-1">
          <li><a :href="url.ritz_room_diffuser" class="link-1" target="_blank">The Ritz-Carlton Shop Room Diffuser Set</a></li>
          <li><a :href="url.ritz_home_diffuser" class="link-1" target="_blank">The Ritz-Carlton Shop Home Diffuser Set</a></li>
          <li><a :href="url.edition_room_diffuser" class="link-1" target="_blank">The <span class="text-uppercase">Edition</span> Shop Room Diffuser Set</a></li>
          <li><a :href="url.edition_home_diffuser" class="link-1" target="_blank">The <span class="text-uppercase">Edition</span> Shop Home Diffuser Set</a></li>
          <li><a :href="url.westin_room_diffuser" class="link-1" target="_blank">The Westin Store Room Diffuser Set</a></li>
          <li><a :href="url.westin_home_diffuser" class="link-1" target="_blank">The Westin Store Home Diffuser Set</a></li>
        </ul>
      </li>
      <li>The Marriott Retail Brands Lifetime Recognition 40% Discount promotional code is valid through April 1, 2023. Promotion is valid while supplies last.</li>
      <li>The 40% discount promotional code is nonrefundable and nontransferable.</li>
      <li>The promotional code cannot be duplicated or sold, and there is no cash value. In the event <span class="no-wrap">Marriott Bonvoy</span><sup>®</sup> suspects that the use or redemption of the promotional code was in error, fraudulent, illegal or in violation of these terms and conditions or the <a :href="url.program_rules" class="link-1" target="_blank">Program Rules</a>, <span class="no-wrap">Marriott Bonvoy</span> reserves the right to withhold or deduct the promotional code or corresponding credit. The promotional code may be terminated or modified at any time and without any liability to <span class="no-wrap">Marriott Bonvoy</span>. The promotional code will be used for its intended and lawful purpose and pursuant to these terms and conditions and the <a :href="url.program_rules" class="link-1" target="_blank">Program Rules</a>. The promotional code is void where prohibited by law. The promotional code is for a single purchase only.</li>
      <li>The 40% discount promotional code is not combinable with other offers. The promotional code is for a one-time use on a single product and is nontransferable. Only the member who is recognized as a Lifetime Titanium or Lifetime Platinum Elite member will be able to redeem the 40% discount promotional code.</li>
      <li>Prior to shipping the applicable merchandise, Marriott reserves the right to verify that the person who redeems the 40% discount promotional code is the member who is recognized as the Lifetime Titanium or Lifetime Platinum Elite member.</li>
      <li>Shipping, taxes, VAT, and/or duties are not included and varies by region.</li>
    </ul>
  </ModalBase>
</template>

<script setup>
import ModalBase from '../components/ModalBase';

const url = content.url;
</script>
