import { createApp } from 'vue';

import GiftSection from './components/GiftSection';
import SectionLeadIn from './components/SectionLeadIn';
import ModalSocialShare from './components/ModalSocialShare';
import ModalShare from './components/ModalShare';
import ModalEdit from './components/ModalEdit';
import ModalBoutiques from './components/ModalBoutiques';
import RegionsMap from './components/RegionsMap';
import CelebratePage from './components/CelebratePage';

import { createPinia } from 'pinia';

const app = createApp({});

app.component('GiftSection', GiftSection);
app.component('SectionLeadIn', SectionLeadIn);
app.component('ModalSocialShare', ModalSocialShare);
app.component('ModalShare', ModalShare);
app.component('ModalEdit', ModalEdit);
app.component('ModalBoutiques', ModalBoutiques);
app.component('RegionsMap', RegionsMap);
app.component('CelebratePage', CelebratePage);

app.use(createPinia());
app.mount('#app');
