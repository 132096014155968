<template>
  <button v-if="array.length" @click="onRemoveModule" @keydown.enter="onRemoveModule" class="remove-module">
    <span>&times;</span>
  </button>
</template>

<script setup>
const props = defineProps(['array']);
const emit = defineEmits(['event']);

function onRemoveModule() {
  emit('event');
}
</script>

<style lang="scss" scoped>
@import 'resources/scss/variables';

$btn-size: 37px;

#app {
  button.remove-module {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc($btn-size / -2);
    right: calc($btn-size / -2);
    width: $btn-size;
    height: $btn-size;
    background-color: $gray-dk;
    border-radius: 50%;
    z-index: 9;

    >* {
      font-family: $font-swiss-medium;
      color: #fff;
      font-size: 33px;
      font-weight: bold;
      line-height: 1;
      height: $btn-size;
    }

    @media #{$mxw-social-share-modal} {
      $btn-size: 25px;
      top: calc($btn-size / -2);
      right: calc($btn-size / -2);
      width: $btn-size;
      height: $btn-size;

      >* {
        font-size: 22px;
        height: $btn-size;
      }
    }

    @media #{$mxw-sm} {
      $btn-size: 15px;
      top: calc($btn-size / -2);
      right: calc($btn-size / -2);
      width: $btn-size;
      height: $btn-size;

      >* {
        font-size: 13px;
        height: $btn-size;
      }
    }
  }
}
</style>
