<template>
  <div class="gift-confirm mxw-md pt-5">

    <div v-if="store.gift.optInStatus === 'opt_in'">
      <div class="text-center">
        <h2 class="heading">Your Gift Is Confirmed</h2>
        <p>You’ve confirmed your complimentary luggage tag set. You’ll receive it at the shipping address you provided within approximately <span class="no-wrap">12 weeks.</span></p>
      </div>
      <div class="info-wrap flex-grid bg-bonvoy-white rounded mt-3 p-2">
        <div>
          <h3 class="info-heading">Shipping Information:</h3>
          <p class="name">{{ store.gift.firstName }} {{ store.gift.lastName }}</p>
          <p class="address-1">{{ store.gift.address1 }}</p>
          <p v-if="store.gift.address2" class="address-2">{{ store.gift.address2 }}</p>
          <p class="city-state-zip">{{ store.gift.city }}{{ store.stateProvDisplay }} {{ store.gift.postalCode }}</p>
          <p class="country">{{ store.gift.countryCode }}</p>
        </div>
        <div>
          <h3 class="info-heading">Contact Information:</h3>
          <p class="phone">{{ store.gift.phoneNumber }}</p>
          <p class="email">{{ store.gift.emailAddress }}</p>
        </div>
      </div>
    </div>

    <div v-else class="text-center">
      <h2 class="heading">You’ve Opted Out of Your <span class="no-wrap">Elite Gift</span></h2>
      <p>Thank you for your response, as well as your many years of loyalty. Continue to explore your Lifetime {{ store.levelName }} Journey below. We look forward to creating even more <span class="no-wrap">memories together.</span></p>
    </div>

    <p class="text-center fz-xs mt-3">If you have any questions, please see our <a :href="faqsHref" class="link-1" target="_blank">FAQs</a>.</p>

  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from '../store';

const store = useStore();

const faqsHref = ref(content.url.faqs);
</script>
