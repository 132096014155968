<template>
  <div class="container">
    <div class="mxw-body">

      <section>
        <div class="text-center">
          <h1 class="heading mb-3">We’d Love to Hear From You</h1>
          <p>Wherever your journeys lead you, we hope this luggage tag set* serves as a reminder of our gratitude, as well as a symbol of your inclusion in the exclusive Lifetime Elite community. We’d love to hear your thoughts and greatly appreciate your responses to these brief questions.</p>
        </div>
      </section>

      <section>
        <form id="surveyform" action="/form/survey">

          <div class="question-1">
            <p class="question">1. Which luggage tag is your favorite?</p>
            <div class="flex-grid">
              <div class="d-flex align-items-center">
                <img src="/images/tag_colorful.jpg" alt="">
                <div class="input-wrap radio">
                  <input type="radio" name="favoriteTag" id="tag-1" value="colorful">
                  <label for="tag-1">Colorful and worldly</label>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <img src="/images/tag_elevated.jpg" alt="">
                <div class="input-wrap radio">
                  <input type="radio" name="favoriteTag" id="tag-2" value="elevated">
                  <label for="tag-2">Elevated and refined</label>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <img src="/images/tag_both.jpg" alt="">
                <div class="input-wrap radio">
                  <input type="radio" name="favoriteTag" id="tag-3" value="both">
                  <label for="tag-3">I like them both!</label>
                </div>
              </div>
            </div>
          </div>

          <div class="question-2">
            <p class="question">2. What destination will you be taking your luggage tag set to next?</p>
            <div class="input-wrap">
              <textarea name="nextDestination" id="destination" cols="30" rows="3" maxlength="2000"></textarea>
            </div>
          </div>

          <div class="question-3">
            <p class="question">3. Do you have any feedback on your Lifetime Journey recognition experience, such as elements you liked or suggestions for improvements?</p>
            <div class="input-wrap">
              <textarea name="feedback" id="feedback" cols="30" rows="3" maxlength="2000"></textarea>
            </div>
          </div>

          <button @click.prevent="onConsent" @keydown.enter.prevent="onConsent" class="input-wrap checkbox mt-5 mb-0">
            <input type="checkbox" id="consent" name="consent" value="1">
            <label for="consent" class="text-left fz-2xs mb-0">I give consent to Marriott Bonvoy<sup>®</sup> to contact me regarding my response.</label>
            <span class="checkmark"></span>
          </button>

          <p class="fz-2xs mt-2">By submitting a survey response, you grant Marriott International, Inc. the unrestricted right and permission to use, in perpetuity, names and any comments included in your survey response, in whole or in part, and to use, edit, present, transmit, and display the same, without restriction as to changes or transformation, in connection with its business, in all forms and media now known or hereafter developed, without payment of royalties.</p>

          <button @click.prevent="onSubmit" @keydown.enter.prevent="onSubmit" type="submit" class="btn-1 mt-3">Submit</button>

          <div v-if="store.formError" class="errors rounded mt-2 mb-3">
            <p>An error occurred while submitting your response. Please try again.</p>
            <i class="icon-error"></i>
          </div>

        </form>

        <p class="fz-2xs mt-5">*If you haven’t yet opted in to receive your complimentary luggage tag set, return to your <a :href="store.celebrateLifetimeUrl" class="link-1" target="_blank">Lifetime Journey</a> and claim yours.</p>
      </section>

    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue';
import { useStoreCelebrate } from '../store';
import { q } from '../utilities';
import axios from 'axios';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

const store = useStoreCelebrate();

function onConsent() {
  const checkboxEl = q('#celebrate-page input[type="checkbox"]');
  if (checkboxEl.checked) {
    checkboxEl.checked = false
    checkboxEl.value = 0;
  } else {
    checkboxEl.checked = true
    checkboxEl.value = 1;
  }
}

function onSubmit() {
  const formEl = q('#surveyform');
  const url = formEl.getAttribute('action');
  const formData = new FormData(formEl);

  store.formError = false;

  axios
    .post(url, formData)
    .then(function (response) {
      store.surveyComplete = true;
      window.scrollTo(0, 0);
    })
    .catch(function (error) {
      store.formError = true;
      console.log('error');
    })
    .then(function () {
      // hide spinner?
    });
}
</script>
