<template>
  <div class="modal fade" :id="props.id" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div :class="`modal-content ${props.class ? props.class : ''}`">
        <button type="button" class="close" :class="closeBtnStyle === 'v2' ? 'close-btn-v2' : ''" data-dismiss="modal" aria-label="Close" @click.prevent="onClose" @keydown.enter.prevent="onClose">
          <span v-if="closeBtnStyle === 'v2'" class="pr-1" aria-hidden="true">Close</span>
          <span class="close-x" aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  id: String,
  class: String,
  closeBtnStyle: String,
});

const emit = defineEmits(['close']);

function onClose() {
  $(`#${props.id}`).modal('hide');
  emit('close');
}
</script>
