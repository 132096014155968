import './init-vue';
import {
  q,
  clickAndEnterListener,
  getElementDistanceFromTop,
  forEachIfArray,
  makeId,
} from './utilities';
import Swiper, { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { initOdometer } from './odometer';

// -------------
// Hero video
let videoControlsInterval;

function startVideo() {
  q('.video-1-wrap').classList.add('hide');
  q('.video-2-wrap').classList.remove('hide');
  q('video#video-2').play();
}

clickAndEnterListener(q('#video-start-btn'), startVideo);

function showCustomVidBtns() {
  q('.custom-video-btns').classList.remove('hidden');
  clearInterval(videoControlsInterval);
  videoControlsInterval = setInterval(hideCustomVidBtns, 2100);
}

function hideCustomVidBtns() {
  q('.custom-video-btns').classList.add('hidden');
}

const videoHoverEls = [ q('video#video-2'), q('.custom-video-btns') ];

videoHoverEls.forEach(el => {
  el.addEventListener('mouseover', showCustomVidBtns);
  el.addEventListener('mousemove', showCustomVidBtns);
  el.addEventListener('mouseleave', hideCustomVidBtns);
});

q('video#video-2').addEventListener('ended', () => {
  clearInterval(videoControlsInterval);
  q('.custom-video-btns').classList.remove('hidden');
});

function onVideoClose() {
  q('.video-2-wrap').classList.add('hide');
  q('.video-1-wrap').classList.remove('hide');
  q('video#video-2').pause();
}

clickAndEnterListener(q('#close-video-btn'), onVideoClose);

// -------------
// Stats values animation
initOdometer();

const statsValues = [
  stats.TotalPointsEarnedDisplay,
  stats.TotalPointsRedeemedDisplay,
  stats.TotalNightsDisplay,
  stats.TotalBrandsDisplay
];
let odometerNotTriggered = true;

function triggerOdometer() {
  if (odometerNotTriggered) {
    for (let i = 0; i < q('.highlights-grid .stats-value').length; i++) {
      const instance = new Odometer({
        el: q(`#stats-value-${i+1}`),
        duration: 2000,
      });
      instance.update(statsValues[i]);
    }
    odometerNotTriggered = false;
    document.removeEventListener('scroll', triggerOdometerIfInView);
    window.removeEventListener('resize', triggerOdometerIfInView);
  }
}

function triggerOdometerIfInView() {
  const fromTop = getElementDistanceFromTop(q('.grid-child.child-1'));
  const elementOffset = q('.grid-child.child-1').offsetHeight;
  if (
    (window.scrollY + window.innerHeight) - elementOffset >= fromTop &&
    window.scrollY <= fromTop
  ) {
    triggerOdometer();
  }
}

triggerOdometerIfInView();
document.addEventListener('scroll', triggerOdometerIfInView);
window.addEventListener('resize', triggerOdometerIfInView);

// -------------
// Update when page nav is sticky or not
function updateStickyNav() {
  const headerEl = q('.global-header');
  const pageNavEl = q('#page-nav');
  const aboveNavEl = q('.above-nav');
  const pageNav_h = pageNavEl.offsetHeight;
  const header_h = headerEl.offsetHeight;
  const aboveNav_h = aboveNavEl.offsetHeight;

  if (window.scrollY >= aboveNav_h) {
    headerEl.style.position = 'absolute';
    headerEl.style.top = aboveNav_h + 'px';
    headerEl.classList.add('no-shadow');
  } else {
    headerEl.style.position = 'fixed';
    headerEl.style.top = 0;
    headerEl.classList.remove('no-shadow');
  }

  if (window.scrollY >= aboveNav_h + header_h) {
    pageNavEl.classList.add('sticky');
    aboveNavEl.style.marginBottom = `${pageNav_h}px`;
  } else {
    pageNavEl.classList.remove('sticky');
    aboveNavEl.style.marginBottom = 0;
  }
}

updateStickyNav();
document.addEventListener('scroll', updateStickyNav);
window.addEventListener('resize', updateStickyNav);

// -------------
// Update active state in page nav
q('.section-anchor').forEach((el, index) => {
  el.id = makeId(pageNavContent[index].copy);
});

function updateActiveNavItem() {
  const halfViewportHeight = window.innerHeight / 2;
  const pageNav_h = q('#page-nav').offsetHeight;
  const addDividerOffset = (el) => Math.round(getElementDistanceFromTop(el) - pageNav_h);

  let sectionDividerOffsets = [];
  q('.nav-section').forEach((el) => {
    sectionDividerOffsets.push(addDividerOffset(q(`#${el.id}`)));
  });

  function addRemoveActiveClasses(target) {
    if (q('#page-nav a.active')) {
      q('#page-nav a.active').forEach(el => el.classList.remove('active'));
    }
    q(`#page-nav a[href="${target}"`).forEach(el => el.classList.add('active'));
  }

  pageNavContent.forEach((item, index) => {
    if (index === 0) {
      if (window.scrollY < sectionDividerOffsets[0] - halfViewportHeight) {
        addRemoveActiveClasses(`#${makeId(item.copy)}`);
      }
    } else if (index === pageNavContent.length - 1) {
      if (window.scrollY > sectionDividerOffsets[index - 1] - halfViewportHeight) {
        addRemoveActiveClasses(`#${makeId(item.copy)}`);
      }
    } else {
      if (
        window.scrollY > sectionDividerOffsets[index - 1] - halfViewportHeight &&
        window.scrollY < sectionDividerOffsets[index] - halfViewportHeight
      ) {
        addRemoveActiveClasses(`#${makeId(item.copy)}`);
      }
    }
  });
}

updateActiveNavItem();
window.addEventListener('scroll', updateActiveNavItem);
document.addEventListener('resize', updateActiveNavItem);

// -------------
// Brand circle flipping
forEachIfArray(q('.flipping-circle .wrap'), (el) => {
  clickAndEnterListener(el, (e) => e.target.classList.toggle('flip'));
});

// -------------
// Init swipers
new Swiper('.swiper-moments', {
  slidesPerView: 'auto',
  loop: true,
  loopedSlides: content.moments.length,
  modules: [Pagination],
  pagination: {
    el: '.swiper-pagination-moments',
    clickable: true,
  },
});

const swiperRedemptionHighlightsOptions = {
  slidesPerView: 'auto',
  modules: [Navigation, Pagination],
  centeredSlides: true,
  pagination: {
    el: '.swiper-pagination-redemption-highlights',
    clickable: true,
    bulletElement: 'button',
  },
  navigation: {
    nextEl: '.swiper-button-redemption-highlights-next',
    prevEl: '.swiper-button-redemption-highlights-prev',
  },
};

if (Object.keys(redemptionHighlights).length !== 2) {
  swiperRedemptionHighlightsOptions.loop = true;
}

new Swiper('.swiper-redemption-highlights', swiperRedemptionHighlightsOptions);

new Swiper('.swiper-redemptions', {
  slidesPerView: 'auto',
  loop: true,
  loopedSlides: content.redemptions.length,
  modules: [Pagination],
  pagination: {
    el: '.swiper-pagination-redemptions',
    clickable: true,
  },
});

new Swiper('.swiper-boutiques', {
  slidesPerView: 'auto',
  loop: true,
  loopedSlides: content.boutiques_slider.length,
  modules: [Navigation, Pagination],
  pagination: {
    el: '.swiper-pagination-boutiques',
    clickable: true,
    bulletElement: 'button',
  },
  navigation: {
    nextEl: '.swiper-button-boutiques-next',
    prevEl: '.swiper-button-boutiques-prev',
  },
  breakpoints: {
    600: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

function setSwiperNavWidth(swiperClass, contentSource) {
  if (q(`${swiperClass} .swiper-pagination-bullet`)) {
    const pagDotWidth = q(`${swiperClass} .swiper-pagination-bullet`)[0].offsetWidth;
    let pagDotAmount;
    if (Array.isArray(contentSource)) {
      pagDotAmount = contentSource.length;
    } else if (typeof contentSource === 'object' && contentSource !== null) {
      pagDotAmount = Object.keys(contentSource).length;
    }
    const pagDotSpaceBetween = 8;
    const pagOuterPad = 20;
    const navBtnWidth = q(`${swiperClass} .swiper-button-prev`).offsetWidth;
    const pagWrapWidth =
      pagDotWidth *
      pagDotAmount +
      (pagDotSpaceBetween * (pagDotAmount - 1)) +
      pagOuterPad +
      (navBtnWidth * 2);

    q(`${swiperClass} .nav-wrapper`).style.width = pagWrapWidth + 'px';
  }
}

setSwiperNavWidth('.swiper-boutiques', content['boutiques_slider']);
setSwiperNavWidth('.swiper-redemption-highlights', redemptionHighlights);
