<template>
  <ModalBase id="modal-share" class="bg-bonvoy-white fz-2xs">
    <p>Copy and share this link</p>
    <div class="copy-url mt-1">
      <div class="d-flex align-items-center flex-auto mr-2">
        <img src="/images/icon_link.png" alt="">
        <input type="text" :value="store.videoShareUrl" readonly>
      </div>
      <div class="btn-wrap position-relative">
        <button @click="copyUrl" @keydown.Enter="copyUrl" id="copy-url-btn" :class="btnClass">{{ btnCopy }}</button>
      </div>
    </div>
  </ModalBase>
</template>

<script setup>
import { ref } from 'vue';
import ModalBase from '../components/ModalBase';
import { useStore } from '../store';

const btnClass = ref('btn-1');
const btnCopy = ref('Copy');
const store = useStore();

function copyUrl() {
  navigator.clipboard.writeText(store.videoShareUrl);
  btnClass.value = 'btn-2-bd';
  btnCopy.value = 'Copied!';
  setTimeout(() => {
    btnClass.value = 'btn-1';
    btnCopy.value = 'Copy';
  }, 3000);
}
</script>
