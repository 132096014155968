<template>
  <ModalBase id="modal-edit" class="bg-bonvoy-white" @close="onCancel">
    <p class="fz-3xs pr-3 pr-md-0">Please ensure your shipping address and contact information are correct. <br class="d-none d-md-inline">Updating your address here will not update the address listed on your member profile.</p>
    <form class="fz-2xs mt-2">
      <h3 class="info-heading">Shipping Information:</h3>

      <div class="flex-grid section-gap">
        <div class="input-wrap">
          <label for="first-name">First Name</label>
          <input
            type="text"
            id="first-name"
            class="required-input"
            v-model="state.firstName"
            @input="onInput($event, true)"
            placeholder="Firstname"
            maxlength="30"
          >
          <p class="error-msg">First name is required</p>
        </div>
        <div class="input-wrap">
          <label for="last-name">Last Name</label>
          <input
            type="text"
            id="last-name"
            class="required-input"
            v-model="state.lastName"
            @input="onInput($event, true)"
            placeholder="Lastname"
            maxlength="30"
          >
          <p class="error-msg">Last name is required</p>
        </div>
      </div>

      <div class="flex-grid section-gap">
        <div class="input-wrap">
          <label for="country">Country/Region</label>
          <select
            name=""
            id="country"
            class="required-input"
            v-model="state.countryCode"
            @input="onInput($event, true)"
          >
            <option v-for="(country, index) in store.ref.countries" :value="country.CountryCode" :key="index">{{ country.Label }}</option>
          </select>
          <p class="error-msg">Country / Region is required</p>
        </div>
      </div>

      <div class="flex-grid align-items-end section-gap">
        <div class="input-wrap">
          <label id="address-label">Address</label>
          <input
            type="text"
            id="address-1"
            class="required-input"
            v-model="state.address1"
            @input="onInput($event, true)"
            placeholder="Street Address 1"
            aria-labelledby="address-label"
          >
          <p class="error-msg address">Address is required. Please enter an address that is not a PO box.</p>
        </div>
        <div class="input-wrap">
          <input
            type="text"
            id="address-2"
            v-model="state.address2"
            @input="onInput($event)"
            placeholder="Street Address Line 2 (Optional)"
            aria-labelledby="address-label"
          >
        </div>
      </div>

      <div class="flex-grid section-gap">
        <div class="input-wrap">
          <label for="city">City</label>
          <input
            type="text"
            id="city"
            class="required-input"
            v-model="state.city"
            @input="onInput($event, true)"
            placeholder="City"
          >
          <p class="error-msg">City is required</p>
        </div>
        <div class="state-zip-wrap d-flex justify-content-between">
          <div class="input-wrap" v-if="stateProvList">
            <label for="state">State/Province</label>
            <select
              name=""
              id="state"
              class="required-input"
              v-model="state.stateProv"
              @input="onInput($event, true)"
              @change="state.stateProvText = null"
            >
              <option v-for="(stateProv, index) in stateProvList" :value="stateProv.StateCode" :key="index">{{ stateProv.Label }}</option>
            </select>
            <p class="error-msg">State / Province Required</p>
          </div>
          <div class="input-wrap" v-if="!stateProvList">
            <label for="stateProvText">State/Province</label>
            <input
              type="text"
              id="stateProvText"
              name="stateProvText"
              v-model="state.stateProvText"
              @change="state.stateProv = null"
              placeholder="State/Province"
              maxlength="60"
            >
          </div>
          <div class="input-wrap ml-2">
            <label for="zip">Postal Code</label>
            <input
              type="text"
              id="zip"
              class="required-input"
              v-model="state.postalCode"
              @input="onInput($event, true)"
              placeholder="Postal Code"
            >
            <p class="error-msg">Postal code is required</p>
          </div>
        </div>
      </div>

      <h3 class="info-heading mt-3 mb-1">Contact Information:</h3>

      <div class="flex-grid section-gap">
        <div class="phone-number-wrap d-flex justify-content-between">
          <div class="input-wrap">
            <label for="code">Country Code</label>
            <select
              name=""
              id="code"
              class="required-input"
              v-model="state.phoneCountryCode"
              @input="onInput($event, true)"
            >
              <option v-for="(i, index) in countryCodes" :value="i.CountryCode" :key="index">{{ i.Label }}</option>
            </select>
            <p class="error-msg">Phone country code is required</p>
          </div>
          <div class="input-wrap ml-2">
            <label for="phone">Phone Number</label>
            <input
              type="text"
              id="phone"
              class="required-input"
              v-model="state.phoneNumber"
              @input="onInput($event, true, 'phone')"
              placeholder="Phone Number"
              maxlength="14"
            >
            <p class="error-msg">Phone number is required</p>
          </div>
        </div>
        <div class="input-wrap">
          <label for="email">Email</label>
          <input
            type="text"
            id="email"
            class="required-input"
            v-model="state.emailAddress"
            @input="onInput($event, true, 'email')"
            placeholder="Email"
            maxlength="255"
          >
          <p class="error-msg">A valid email is required</p>
        </div>
      </div>

      <div class="d-flex align-items-center mt-2 mb-1">
        <button
          type="button"
          @click="onSave"
          @keydown.enter="onSave"
          class="btn-1"
          :disabled="store.gift.allRequiredFieldsFilled ? false : true"
          data-dismiss="modal"
        >Save</button>

        <button
          type="button"
          @click="onCancel"
          @keydown.enter="onCancel"
          class="link-2 ml-3"
          data-dismiss="modal"
        >Cancel</button>
      </div>
    </form>
  </ModalBase>
</template>

<script setup>
import { computed, reactive, ref, onMounted } from 'vue';
import { useStore } from '../store';
import ModalBase from '../components/ModalBase';
import { q, validateEmail } from '../utilities';

const store = useStore();

const fieldKeys = [
  'firstName',
  'lastName',
  'address1',
  'address2',
  'city',
  'stateProv',
  'stateProvText',
  'postalCode',
  'countryCode',
  'phoneCountryCode',
  'phoneNumber',
  'emailAddress',
];

const stateObject = {};

for (const key of fieldKeys) {
  stateObject[key] = store.gift[key];
}

const state = reactive(stateObject);

const stateProvList = computed(() => {
  if (store.gift.optInStatus === 'opt_in') {
    return null;
  }

  const values = store.ref.states[state.countryCode];

  if (!values) {
    state.stateProv = null;
  }

  return values;
});

function onSave() {
  for (const key of fieldKeys) {
    store.gift[key] = state[key];
  }
}

function onCancel() {
  for (const key of fieldKeys) {
    state[key] = store.gift[key];
  }
  setTimeout(() => {
    checkRequiredFieldsFilled();
  }, 100);
}

const countryCodes = store.ref.countryPhones;
const countriesNoPostalArray = countriesNoPostal.split(',');
let zipIsRequired;

function checkRequiredFieldsFilled() {
  let requiredFieldsValues = [];
  let allRequiredFieldsFilled;

  q('#modal-edit .required-input').forEach(el => {
    const inputWrap = el.closest('.input-wrap');

    if (!el.value) {
      inputWrap.classList.add('error');
    } else {
      inputWrap.classList.remove('error');
    }

    switch (el.id) {
      case 'country':
        zipIsRequired = !countriesNoPostalArray.some(code => code === el.value);
        requiredFieldsValues.push(el.value);
        break;

      case 'zip':
        if (zipIsRequired) {
          requiredFieldsValues.push(el.value);
          if (!el.value) {
            inputWrap.classList.add('error');
          }
        } else {
          requiredFieldsValues.push('not required');
          inputWrap.classList.remove('error');
        }
        break;

      case 'email':
        if (validateEmail(el.value)) {
          requiredFieldsValues.push(el.value);
          inputWrap.classList.remove('error');
        } else {
          requiredFieldsValues.push('');
          inputWrap.classList.add('error');
        }
        break;

      default:
        requiredFieldsValues.push(el.value);
    }
  });

  allRequiredFieldsFilled = requiredFieldsValues.every(val => val);
  store.gift.allRequiredFieldsFilled = allRequiredFieldsFilled;
}

let newCountryCode = ref(null);

function onInput(e, isRequired = false, inputType) {
  setTimeout(() => {
    if (q('#state')) {
      if (q('#state').value) {
        q('#state').closest('.input-wrap').classList.remove('error');
      } else {
        q('#state').closest('.input-wrap').classList.add('error');
      }
    }
    if (isRequired) checkRequiredFieldsFilled();
  }, 100);
}

onMounted(() => {
  checkRequiredFieldsFilled();

  q('#modal-edit .required-input').forEach(el => {
    if (!el.value) {
      el.closest('.input-wrap').classList.add('error');
    }
  });

  $('#modal-edit').on('hide.bs.modal', onCancel);
});
</script>
