<template>
  <ModalBase id="modal-social-share" closeBtnStyle="v2">

    <div class="intro text-center">
      <h2 class="heading">Customize and Share Your <span class="no-wrap">Lifetime Journey</span></h2>
      <p class="body font-aldine-light pt-0-5 px-2">Choose your favorite highlights, select a background, and share with the world — or at least with those who mean the world <span class="no-wrap">to you.</span></p>
      <p class="instructions font-aldine-italic">Remove any stats below that you don’t want to include.</p>
    </div>

    <div class="modules-selector position-relative" :style="`background-color: ${bgColor}; padding: min(3.4375em, 55px) min(3.4375em, 55px) ${modulesSelectorPaddingBottom};`">
      <div class="heading-wrap text-center position-relative z-index-9">
        <h2 class="heading lead-in font-aldine-italic mb-0">Marriott Bonvoy<sup>®</sup> Lifetime {{ _data.levelName }} Elite </h2>
        <h3 class="heading title mb-0">{{ _data.firstName }}’s Journey</h3>
      </div>

      <!-- HIGHLIGHTS STATS -->
      <section v-if="highlightsStatsTopRow.length || highlightsStatsBottomRow.length" class="highlights-stats">
        <div v-if="highlightsStatsTopRow.length" class="row-wrap">
          <div v-for="(stat, index) in highlightsStatsTopRow" :key="index" :class="`module ${stat.class} text-center position-relative`">
            <ModalSocialShareRemoveBtn :array="highlightsStatsTopRow" @event="onRemoveModule(highlightsStatsTopRow, index)" />
            <div>
              <div class="text-wrap">
                <p class="label mb-0">{{ stat.bottom_label }} {{ stat.top_label }}</p>
                <p class="value">{{ numberWithCommas(stat.value) }}</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="highlightsStatsBottomRow.length" class="row-wrap">
          <div v-for="(stat, index) in highlightsStatsBottomRow" :key="index" :class="`module ${stat.class} text-center position-relative`">
            <ModalSocialShareRemoveBtn :array="highlightsStatsBottomRow" @event="onRemoveModule(highlightsStatsBottomRow, index)" />
            <div class="d-flex align-items-center">
              <img v-if="stat.img" :src="stat.img" alt="">
              <div class="text-wrap text-left custom-ml-3">
                <p class="value mb-0">{{ numberWithCommas(stat.value) }} <span class="value-2">of 34</span></p>
                <p class="label">{{ stat.bottom_label }} {{ stat.top_label }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- TOP BRANDS -->
      <section v-if="_data.topBrands.length" class="top-brands">

        <ModalSocialShareRemoveBtn :array="_data.topBrands" @event="onRemoveModule(_data.topBrands, index, true)" />

        <div :class="`module d-flex justify-content-center align-items-center brands-${_data.topBrands.length}`">
          <h2 class="heading text-center mb-0">The Brand{{ _data.topBrands.length === 1 ? '' : 's' }} I Stayed <span class="no-wrap">With Most</span></h2>
          <div class="flipping-circle d-flex justify-content-center">
            <div v-for="(item, index) in _data.topBrands" :key="index" class="wrap text-center">
              <div class="face face-back">
                <div class="back-wrap">
                  <img :src="`/images/brand-logos/${item.asset}.png`" :alt="item.brand.BrandName">
                  <div class="d-flex flex-column">
                    <p class="stays mb-0">{{ item.stays }}</p>
                    <p class="stays-label">{{ `Stay${item.stays === 1 ? '' : 's'}` }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- TOP CITIES -->
      <section v-if="_data.topCities.length" class="top-cities">

        <ModalSocialShareRemoveBtn :array="_data.topCities" @event="onRemoveModule(_data.topCities, index, true)" />

        <h2 class="heading text-center">My Most-Visited Cit{{ _data.topCities.length === 1 ? 'y' : 'ies' }}</h2>

        <div class="cities position-relative" :class="`city-amount-${_data.topCities.length}`">
          <div class="img-wrap">
            <img src="/images/icon_most-visited.png" alt="">
          </div>
          <div class="city-wrap d-flex justify-content-center align-items-center">
            <ul>
              <li v-for="(city, index) in _data.topCities" :key="index" :class="index % 2 === 0 ? 'text-right' : ''">{{ index + 1 }}. {{ city }}</li>
            </ul>
          </div>
        </div>
      </section>

      <!-- REDEMPTION HIGHLIGHTS -->
      <section v-if="_data.redemptions.length" class="redemption-highlights">
        <div v-for="(item, index) in _data.redemptions" :key="index" class="module position-relative">

          <ModalSocialShareRemoveBtn :array="_data.redemptions" @event="onRemoveModule(_data.redemptions, index)" />

          <div class="d-flex">
            <div class="left d-flex">
              <img :src="`/images/redemption-highlights/${item.redemptionImg}_d.jpg`" alt="" style="width: min(144*$px, 144px);">
              <div class="wrap d-flex flex-column justify-content-center align-items-center border-right text-center w-100">
                <h2 class="heading mb-0">{{ redemptionsContent[item.RedemptionTypeCode].heading }}</h2>
                <div class="bg-line">
                  <p class="heading-caps font-swiss-light mb-0">In {{ item.date }}</p>
                </div>
                <p class="points mb-0" style="line-height: 1;">{{ item.points }}</p>
                <p class="points-label">Points Redeemed</p>
              </div>
            </div>
            <div class="right d-flex align-items-center">
              <img :src="`/images/brand-logos-social-share/${item.asset}.png`" alt="">
              <p v-html="item.bodyCopy"></p>
            </div>
          </div>
        </div>
      </section>

      <div class="bg" :style="`background-image: url('/images/social-share-modal/bg-${currentBgImg}.jpg')`"></div>

      <div v-if="!highlightsStatsTopRow.length && !highlightsStatsBottomRow.length && !_data.topBrands.length && !_data.topCities.length && !_data.redemptions.length" class="text-center position-relative fz-xl" style="z-index: 8px;">
        <p>No selections made. <a @click="onReset" class="link-1">Reset</a>.</p>
      </div>
    </div> <!-- .modules-selector -->

    <div class="swiper swiper-social-share">
      <p class="instructions text-center pb-4">Select Your Favorite Background</p>
      <div class="swiper-wrapper pb-2">
        <div v-for="(slide, index) in bgColors" class="swiper-slide"></div>
      </div>
      <div class="swiper-pagination swiper-pagination-social-share"></div>
      <div class="d-flex justify-content-center">
        <div class="nav-wrapper">
          <button class="swiper-button-prev swiper-button-social-share-prev"></button>
          <button class="swiper-button-next swiper-button-social-share-next"></button>
        </div>
      </div>
    </div>

    <div class="save text-center">
      <a role="button" :href="saveUrl" class="btn-1 mb-3">Save Your Image</a>
      <p class="reset"><a @click="onReset" @keydown.Enter="onReset" class="link-1">Reset</a> and create again.</p>
      <a @click="onClose" @keydown.Enter="onClose" class="link-1">Close</a>
    </div>

  </ModalBase>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import ModalBase from '../components/ModalBase.vue';
import ModalSocialShareRemoveBtn from './ModalSocialShareRemoveBtn.vue';
import { numberWithCommas } from '../utilities';
import Swiper, { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

// Delete later, for dev only
// setTimeout(() => $('#modal-social-share').modal('show'), 1000);

const props = defineProps(['data']);
const redemptionsContent = content.redemption_highlights;
const baseUrl = '/en-us/social/share/customized-stats';
const queryStringBg = ref('default')
const queryStringDefault = '&pointsEarned&pointsRedeemed&brandsStayed&nightsStayed&topBrands&topCities';
const queryString = ref(queryStringDefault);

const saveUrl = computed(() => {
  return baseUrl + `?bg=${queryStringBg.value}` + queryString.value;
});

// https://stackoverflow.com/questions/53662412/how-can-i-make-a-value-in-vue-js-non-reactive
// the _data ref is reactive but props.data stays as it was
const _data = ref(structuredClone(props.data));

function updateQueryString() {
  const pointsEarned = highlightsStatsTopRow.value.some((item) => item.class === 'module-1');
  const pointsRedeemed = highlightsStatsTopRow.value.some((item) => item.class === 'module-2');
  const brandsStayed = highlightsStatsBottomRow.value.some((item) => item.class === 'module-3');
  const nightsStayed = highlightsStatsBottomRow.value.some((item) => item.class === 'module-4');
  function removeTextIf(condition, text) {
    if (condition) {
      queryString.value = queryString.value.replace(text, '');
    }
  }
  removeTextIf(!pointsEarned, '&pointsEarned');
  removeTextIf(!pointsRedeemed, '&pointsRedeemed');
  removeTextIf(!nightsStayed, '&nightsStayed');
  removeTextIf(!brandsStayed, '&brandsStayed');
  removeTextIf(!_data.value.topBrands.length, '&topBrands');
  removeTextIf(!_data.value.topCities.length, '&topCities');

  // Clear everything after & including '&redemptions' to rebuild later
  queryString.value = queryString.value.split('&redemptions')[0];

  if (Object.getPrototypeOf(_data.value.redemptions) === Object.prototype) {
    _data.value.redemptions = Object.values(_data.value.redemptions);
  }

  if (_data.value.redemptions.length) {
    let delimiter = ',';

    function delimiterLogic(index) {
      delimiter = ',';
      if (index === _data.value.redemptions.length - 1) {
        delimiter = '';
      }
    }

    queryString.value = queryString.value + '&redemptions=';

    _data.value.redemptions.forEach((item, index) => {
      delimiterLogic(index);
      queryString.value = queryString.value + item.RedemptionRank + delimiter;
    });

    queryString.value = queryString.value + '&assets=';

    _data.value.redemptions.forEach((item, index) => {
      delimiterLogic(index);
      queryString.value = queryString.value + `${item.redemptionImg}_d.jpg` + delimiter;
    });
  } else {
    queryString.value = queryString.value.replace('&redemptions=', '');
  }
}

const statsValues = [
  stats.TotalPointsEarnedDisplay,
  stats.TotalPointsRedeemedDisplay,
  stats.TotalBrandsDisplay,
  stats.TotalNightsDisplay,
];

const highlightsStatsTopRow = ref([]);
const highlightsStatsBottomRow = ref([]);

function updateHighlightsStats() {
  // Move 3rd item to the end (switching 3rd & 4th items)
  _data.value.highlightsStats.push(_data.value.highlightsStats.splice(2, 1)[0]);

  _data.value.highlightsStats.forEach((item, i) => {
    item.class = `module-${i + 1}`;
    item.value = statsValues[i];
    if (i === 2) {
      item.bottom_label = 'Brands';
      item.top_label = 'Stayed';
      item.img = `/images/icon_highlights-stats-3.png`;
    }
    if (i === 3) {
      item.img = `/images/icon_highlights-stats-4.png`;
    }
  });

  highlightsStatsTopRow.value = _data.value.highlightsStats.slice(0, 2);
  highlightsStatsBottomRow.value = _data.value.highlightsStats.slice(2);
}
updateHighlightsStats();
updateQueryString();

const currentBgImg = ref(1);
const bgColors = [
  { hex: '#d8e9ff', queryString: 'default' },
  { hex: '#f7e7cc', queryString: 'yellow' },
  { hex: '#afd3f2', queryString: 'blue' },
  { hex: '#cedcdf', queryString: 'green' },
];
const bgColor = ref(bgColors[0]);
const paddingBottom1 = 335;
const paddingBottom2 = 200;
const modulesSelectorPaddingBottom = ref(`min(${paddingBottom1 * 0.0625}em, ${paddingBottom1}px)`);

onMounted(() => {
  const swiperSocialShare = new Swiper('.swiper-social-share', {
    loop: true,
    modules: [Navigation, Pagination],
    pagination: {
      el: '.swiper-pagination-social-share',
      clickable: true,
      bulletElement: 'button',
    },
    navigation: {
      nextEl: '.swiper-button-social-share-next',
      prevEl: '.swiper-button-social-share-prev',
    },
  });

  swiperSocialShare.on('slideChange', () => {
    const swiperIndex = swiperSocialShare.realIndex;
    currentBgImg.value = swiperIndex + 1;
    bgColor.value = bgColors[swiperIndex].hex;
    queryStringBg.value = bgColors[swiperIndex].queryString;
    if (swiperIndex === 3) {
      modulesSelectorPaddingBottom.value = `min(${paddingBottom2 * 0.0625}em, ${paddingBottom2}px)`;
    } else {
      modulesSelectorPaddingBottom.value = `min(${paddingBottom1 * 0.0625}em, ${paddingBottom1}px)`;
    }
  });
}); // onMounted()

function onRemoveModule(array, index, clearAll = false) {
  if (clearAll) {
    array.splice(0);
  } else {
    array.splice(index, 1);
  }
  updateQueryString();
}

function onReset() {
  // https://stackoverflow.com/questions/53662412/how-can-i-make-a-value-in-vue-js-non-reactive
  // _data.value is reset without making props.data reactive
  _data.value = structuredClone(props.data);
  updateHighlightsStats();
  queryString.value = queryStringDefault;
  updateQueryString();
}

function onClose() {
  $('#modal-social-share').modal('hide');
}
</script>

<style lang="scss" scoped>
@import 'resources/scss/_variables';
@import 'resources/scss/_mixins';

$px: .0625em;

#app {
  #modal-social-share {
    font-size: min(1vw, 3.86vh);

    @media #{$mxw-social-share-modal} {
      font-size: min(1.45vw, 3.86vh);
    }
  }

  section {
    position: relative;
    margin-top: min(30*$px, 30px);
    padding-bottom: min(30*$px, 30px);
    z-index: 9;
  }

  .intro {
    max-width: min(880*$px, 800px);
    margin: 40px auto 20px;

    .heading {
      font-size: min(42*$px, 42px);
      margin-bottom: min(16*$px, 30px);
    }

    .body {
      font-size: min(26*$px, 26px);
      line-height: 1.2;
      margin-bottom: min(26*$px, 26px);
    }

    .instructions {
      font-size: min(18*$px, 18px);
    }

    @media #{$mxw-lg} {
      .heading {
        font-size: 32px;
      }

      .body {
        font-size: 18px;
      }

      .instructions {
        font-size: 14px;
      }
    }

    @media #{$mxw-sm} {
      .heading {
        font-size: 26px;
      }

      .body {
        font-size: 16px;
      }

      .instructions {
        font-size: 12px;
      }
    }
  }

  .modules-selector {
    max-width: min(1300*$px, 1200px);
    min-height: min(783*$px, 783px);
    margin: 0 auto;
    background-color: #d8e9ff;

    .heading-wrap {
      margin-bottom: min(43*$px, 43px);
    }

    .lead-in {
      font-size: min(27*$px, 27px);
    }

    .title {
      font-size: min(52*$px, 52px);
    }
  }

  .highlights-stats {
    .row-wrap {
      display: flex;
      justify-content: center;

      .module {
        flex: 0 1 48.7%;
        width: 48.7%;
        box-shadow: $box-shadow-2;
        border-radius: var(--social-share-border-radius);
        padding: min(25*$px, 25px);
        background-color: #fff;

        &:first-of-type {
          margin-right: min(12*$px, 12px);
        }

        &:last-of-type {
          margin-left: min(12*$px, 12px);
        }
      }

      &:nth-of-type(2) {
        margin-top: min(24*$px, 24px);
      }
    }

    .custom-ml-3 {
      margin-left: min(24*$px, 24px);
    }

    .label {
      font-size: min(26*$px, 29px);
      letter-spacing: min(1*$px, 1px);
      text-transform: capitalize;
    }

    .value {
      font-family: $font-aldine-light;
      font-size: min(55*$px, 55px);
      line-height: 1.1;
    }

    .value-2 {
      display: none;
    }

    .module-3 .value-2 {
      display: inline;
      font-size: min(0.7em, 40px);
      color: $gray-dk;
    }

    img {
      width: min(60*$px, 60px);
    }
  }

  // .highlights-stats

  .top-brands {
    .heading {
      width: min(280*$px, 280px);
      font-size: min(32*$px, 35px);
      margin-right: min(30*$px, 30px);
    }

    .brands-1 {
      .heading {
        width: min(168*$px, 280px);
      }

      .flipping-circle {
        width: min(150*$px, 150px);
      }
    }

    .brands-2 {
      .heading {
        width: min(136*$px, 280px);
      }

      .flipping-circle {
        width: min(330*$px, 330px);
      }
    }

    .brands-3 {
      .heading {
        width: min(136*$px, 280px);
      }

      .flipping-circle {
        width: min(526*$px, 526px);
      }
    }

    .flipping-circle {
      // width: unset;
      height: unset;
    }

    .wrap {
      width: unset;
      height: unset;
      margin-right: min(40*$px, 40px);
      pointer-events: none;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .face {
      position: relative;
      width: min(148*$px, 148px);
      height: min(148*$px, 148px);
    }

    .face-back {
      transform: none;
    }

    .stays,
    .stays-label {
      font-family: $font-aldine-light;
      line-height: 1;
    }

    .stays {
      font-size: min(45*$px, 45px);
    }

    .stays-label {
      font-size: min(22*$px, 22px);
    }

    img {
      width: min(65*$px, 65px);
      max-height: min(32*$px, 32px);
      object-fit: contain;
      margin-bottom: min(10*$px, 10px);
    }
  }

  // .top-brands

  // $most-visited-icon-w: 97;

  .top-cities {
    .heading {
      font-size: min(35*$px, 35px);
      margin-bottom: min(21*$px, 21px);
    }

    .img-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: min(97*$px, 97px);
      height: min(97*$px, 97px);
      border-radius: 50%;
      box-shadow: $box-shadow-2;
      background-color: #fff;

      img {
        width: min(75*$px, 75px);
        height: min(75*$px, 75px);
      }
    }

    .city-wrap {
      height: min(97*$px, 97px);

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: min(97*$px * 1.6, 97px * 1.6);
        margin: 0;
        list-style: none;
      }

      li {
        font-size: min(27*$px, 27px);
        padding-bottom: 0;
      }
    }
  }

  // .top-cities

  .redemption-highlights {
    .module {
      border-radius: min(19*$px, 19px);
      background-color: #fff;
      padding: min(19*$px, 19px) min(31*$px, 31px) min(21*$px, 21px) min(23*$px, 23px);
      box-shadow: $box-shadow-2;
      margin-bottom: min(26*$px, 26px);

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .left {
      flex: 0 0 min(405*$px, 405px);
      width: min(405*$px, 405px);
      margin-right: min(5*$px, 5px);

      .wrap {
        padding: 0 min(20*$px, 20px);
      }

      img {
        flex: 0 0 min(115*$px, 115px);
        width: min(115*$px, 115px);
        aspect-ratio: 0.85;
      }

      .bg-line {
        padding: 0 min(20*$px, 20px);

        &:after {
          height: min(2*$px, 2px);
        }
      }

      .heading {
        font-size: min(27*$px, 27px);
      }

      .heading-caps {
        font-size: min(12.8*$px, 21px);
        letter-spacing: min(1.75*$px, 1.75px);
      }

      .points {
        font-family: $font-aldine-light;
        font-size: min(45*$px, 45px);
      }


      .points-label {
        font-family: $font-aldine-italic;
        font-size: min(20*$px, 20px);
      }
    }

    .right {
      img {
        width: min(134*$px, 134px);
        object-fit: contain;
      }

      p {
        font-size: min(20*$px, 20px);
        margin-left: min(12*$px, 12px);
        line-height: 1.2;
      }
    }
  }

  // .redemption-highlights

  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: min(783*$px, 783px);
    // height: min(847*$px, 783px);
    height: min(627*$px, 627px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 100%;
  }

  .swiper-social-share {
    margin: 35px 0 45px;

    @media (max-width: $bp-mob-lg) {
      margin: 35px 0;
    }

    .instructions {
      font-size: 23px;

      @media (max-width: $bp-mob-lg) {
        font-size: 16px;
      }
    }

    .nav-wrapper {
      position: relative;
      width: 240px;

      @media (max-width: $bp-mob-lg) {
        width: 180px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: -12px;
      width: 24px;
      height: 24px;

      &:after {
        font-size: 26px;
      }

      @media (max-width: $bp-mob-lg) {
        top: -5px;
        width: 16px;
        height: 16px;

        &:after {
          font-size: 18px;
        }
      }
    }
  }

  .reset {
    margin-bottom: 40px;

    @media (max-width: $bp-mob-lg) {
      margin-bottom: 20px;
    }
  }

  .save {
    * {
      font-size: 20px;
    }

    [class*='btn'] {
      font-size: 18px;
      border-radius: 30px;
    }

    @media (max-width: $bp-mob-lg) {
      * {
        font-size: 12px;
      }

      [class*='btn'] {
        font-size: 16px;
      }
    }
  }
}
</style>
